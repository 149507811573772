<template>
  <div>
    <div>
      <!--    Delivered Information-->
      <base-detail-card title="Delivered Information" v-if="jobDataInner">
        <el-form
          ref="deliveredScheduleForm"
          :model="jobDataInner"
          label-position="top"
          :validate-on-rule-change="false">
          <div class="contract-content">
            <div class="fields">
              <div class="classification">
                <el-row :gutter="10">
                  <div class="product-container product">
                    <div class="single-row-container">
                      <div>
                        <el-form-item
                          label="Product Name">
                          <el-input
                            class="single-border"
                            readonly
                            :value="(jobDataInner||{}).productName">
                          </el-input>
                        </el-form-item>
                        <el-form-item
                          label="Barge Name">
                          <el-input
                            class="single-border"
                            readonly
                            :value="(jobDataInner||{}).bargeName">
                          </el-input>
                        </el-form-item>
                        <el-form-item
                          label="eBDN No">
                          <el-input
                            class="single-border"
                            readonly
                            :value="(jobDataInner||{}).docNumber">
                          </el-input>
                        </el-form-item>
                        <el-form-item
                          label="eBDN Date">
                          <el-date-picker
                            class="single-border"
                            v-model="(jobDataInner||{}).docDate"
                            type="datetime"
                            format="dd-MM-yyyy HH:mm"
                            readonly
                            value-format="yyyy-MM-dd HH:mm:ss">
                          </el-date-picker>
                        </el-form-item>
                        <el-form-item
                          label="Delivered Quantity">
                          <el-input
                            class="single-border"
                            readonly
                            :value="(jobDataInner||{}).docQty">
                          </el-input>
                        </el-form-item>
                        <el-form-item
                          label="ETC">
                          <el-date-picker
                            class="single-border"
                            v-model="(jobDataInner||{}).etc"
                            type="datetime"
                            format="dd-MM-yyyy HH:mm"
                            readonly
                            value-format="yyyy-MM-dd HH:mm:ss">
                          </el-date-picker>
                        </el-form-item>
                        <el-form-item
                          v-if="(jobDataInner||{}).bdnPdfId"
                          style="min-width:180px;"
                          label="BDN Documents"
                        >
                          <el-link
                            class="view-pdf"
                            icon="el-el-icon-document-edit"
                            @click="previewFileMixin((jobDataInner||{}).bdnPdfId)">
                            <u>{{ $t('viewEBDN') }}</u>
                          </el-link>
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                </el-row>
              </div>
            </div>
          </div>
        </el-form>
      </base-detail-card>
    </div>
  </div>
</template>
<script>
import BaseDetailCard from '@/components/BaseDetailCard'
export default {
  props: {
    jobData: {
      type: Object,
      default: () => {}
    },
    locationOptionProps: Object,
    showTemplate: String
  },
  data () {
    return {
      jobDataInner: null
    }
  },
  components: { BaseDetailCard },
  mounted () {
    this.getJobInfoByJobId(this.jobData.id)
  },
  methods: {
    getJobInfoByJobId (jobId) {
      const url = `${this.$apis.createNewJob}/${jobId}/info`
      this.$request.get({
        url
      }).then(res => {
        if (res?.code === 1000) {
          this.jobDataInner = {
            ...res.data
          }
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.fields {
  .classification {
    width: 100%;

    p {
      margin-top: 0;
      font-weight: bold;
      color: #606266;
    }
    /* sub-module-title */
    .title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: $second-color-font;
      margin: 0;
      padding: 10px 10px 0 10px;
      width: fit-content;
      border-radius: 5px;
    }

    .product-container {
      width: 100%;
      display: flex;

      /deep/ input,
      /deep/ .el-date-editor,
      /deep/ textarea,
      /deep/ .el-input-group__append,
      /deep/ .el-input-group__prepend {
        border: none;
      }

      .delete-btn-block {
        margin-right: 20px;

        > div {
          display: flex;
          align-items: flex-end;
          height: 62px;

          &:first-child {
            height: 84px;
            margin-top: 20px;
          }
        }
      }
    }

    .single-row-container {
      width: calc(100% - 40px);
      box-sizing: border-box;
      overflow-x: auto;
      margin:0 10px;

      > div {
        display: flex;

        .el-form-item {
          min-width: 150px;
        }
        .delete-btn-block {
          position: absolute;
          right: -30px;
          bottom: 0;
        }
      }
    }

    .delete-btn-item {
      padding: 3px;
    }

    .el-row {
      padding:10px;

      .cost {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 10px 0;
      }
    }
  }



  .product {
    /deep/ input,
    /deep/ .el-date-editor,
    /deep/ textarea,
    /deep/ .el-input-group__append,
    /deep/ .el-input-group__prepend {
      border: none;
    }

    .single-border {
      /deep/ input {
        border-radius: 0;
        border-right: 1px solid #DCDFE680 !important;
      }
    }

    /deep/ .no-border {
      input {
        border: none !important;
      }
    }

  }
}
</style>
